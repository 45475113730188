
/*****************************************
* ECCO - MIXINS
*****************************************/

@mixin grid( $breakpointMap... ) {
	$previousMargin: 0;
	$nextMapIndex: 1;

	$firstBreakpoint: nth(nth($breakpointMap, $nextMapIndex), 1);

	float: left;

	@include bp("< #{$firstBreakpoint}") {
		width: 100%;
	}

	@each $breakpoint in $breakpointMap { 
		$nextMapIndex: $nextMapIndex + 1;
		$nextMapValue: if( length($breakpointMap) >= $nextMapIndex, nth(nth($breakpointMap, $nextMapIndex), 1), false );

		$currentBreakpoint: nth($breakpoint, 1);
		$amount: nth($breakpoint, 2);
		$margin: if( length($breakpoint) > 2, nth($breakpoint, 3), $previousMargin );
		$previousMargin: $margin;

		@if $nextMapValue != false {
			@include bp(">= #{$currentBreakpoint}", "< #{$nextMapValue}") {
				@include grid-column( $amount, $margin );
			}
		} @else {
			@include bp(">= #{$currentBreakpoint}") {
				@include grid-column( $amount, $margin );
			}
		}
	}
}


@mixin grid-column( $amount, $margin ) {
	$width: null;

	@if unitless($margin) {
		$margin: percentage(strip-unit($margin) / 100);
	}

	@if unit($margin) == unquote("%") {
		$marginTotal: percentage(strip-unit($margin) / 100);
		$width: (100% - ($marginTotal * ($amount - 1))) / $amount;
	} @else {
		$widthItem: 100% / $amount;
		$marginTotal: ( $margin * ( $amount - 1 ) ) / $amount;
		$width: if( $amount > 1, calc( #{$widthItem} - #{$marginTotal} ), 100%);
	}

	width: $width;
	margin-left: $margin;

	&:nth-of-type(#{$amount}n+1) {
		margin-left: 0;
		clear: left;
	}
}


// @mixin dynamic-grid( $max-items, $gap, $item-min-width: 18em /* cca 290px při běžné velikosti písma */ ) {
// 	$gap-type: type-of($gap);

// 	display: flex;
// 	flex-wrap: wrap;

// 	@if $gap-type == number {
// 		$half-gap: $gap/2;
// 		$is-decimal: str-index("#{$half-gap}", $substring: ".");
		
// 		@debug "is decimal: #{$is-decimal}";

// 		@if $is-decimal == null {
// 			margin-left: -$half-gap;
// 			margin-right: -$half-gap;
// 		} @else {
// 			margin-left: floor(-$half-gap);
// 			margin-right: ceil(-$half-gap);
// 		}

// 		> * {
// 			flex-basis: 100% / $max-items;
// 			display: flex;
// 			flex-grow: 1;
// 			min-width: $item-min-width;
// 			margin-bottom: $gap;
			
// 			@if $is-decimal == null {
// 				padding-left: $half-gap;
// 				padding-right: $half-gap;
// 			} @else {
// 				padding-left: ceil($half-gap);
// 				padding-right: floor($half-gap);
// 			}
// 		}
// 	} @else if $gap-type == list {
// 		> * {
// 			flex-basis: 100% / $max-items;
// 			display: flex;
// 			flex-grow: 1;
// 			min-width: $item-min-width;
// 		}

// 		$next-index: 1;

// 		@each $gap-value in $gap {
// 			$next-index: $next-index + 1;
// 			$length: length($gap-value);

// 			@if $length == 1 {
// 				$half-gap-value: $gap-value/2; 
// 				$is-decimal: str-index("#{$half-gap-value}", $substring: ".");

// 				@if $is-decimal == null {
// 					margin-left: -$half-gap-value;
// 					margin-right: -$half-gap-value;
// 				} @else {
// 					margin-left: floor(-$half-gap-value);
// 					margin-right: ceil(-$half-gap-value);
// 				}

// 				> * {
// 					margin-bottom: $gap-value;
					
// 					@if $is-decimal == null {
// 						padding-left: $half-gap-value;
// 						padding-right: $half-gap-value;
// 					} @else {
// 						padding-left: ceil($half-gap-value);
// 						padding-right: floor($half-gap-value);
// 					}
// 				}
// 			} @else {
// 				$next-value: if( length($gap) >= $next-index, nth(nth($gap, $next-index), 1), false );
// 				$current-breakpoint: nth($gap-value, 1);
// 				$margin: nth($gap-value, 2);

// 				@if $next-value != false {
// 					@include bp(">= #{$current-breakpoint}", "< #{$next-value}") {
// 						$half-gap: $margin / 2;
// 						$is-decimal: str-index("#{$half-gap}", $substring: ".");

// 						@if $is-decimal == null {
// 							margin-left: -$half-gap;
// 							margin-right: -$half-gap;
// 						} @else {
// 							margin-left: floor(-$half-gap);
// 							margin-right: ceil(-$half-gap);
// 						}

// 						> * {
// 							margin-bottom: $margin;
							
// 							@if $is-decimal == null {
// 								padding-left: $half-gap;
// 								padding-right: $half-gap;
// 							} @else {
// 								padding-left: ceil($half-gap);
// 								padding-right: floor($half-gap);
// 							}
// 						}
// 					}
// 				} @else {
// 					@include bp(">= #{$current-breakpoint}") {
// 						$half-gap: $margin / 2;
// 						$is-decimal: str-index("#{$half-gap}", $substring: ".");

// 						@if $is-decimal == null {
// 							margin-left: -$half-gap;
// 							margin-right: -$half-gap;
// 						} @else {
// 							margin-left: floor(-$half-gap);
// 							margin-right: ceil(-$half-gap);
// 						}

// 						> * {
// 							margin-bottom: $margin;
							
// 							@if $is-decimal == null {
// 								padding-left: $half-gap;
// 								padding-right: $half-gap;
// 							} @else {
// 								padding-left: ceil($half-gap);
// 								padding-right: floor($half-gap);
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }


@mixin dynamic-grid( $max-items, $gap, $item-min-width: 18em /* cca 290px při běžné velikosti písma */ ) {
	$gap-type: type-of($gap);
	$basis: 100% / $max-items;

	display: flex;
	flex-wrap: wrap;

	@if $gap-type == number {
		margin-left: -$gap;
		margin-right: 0;

		> * {
			//flex-basis: calc(#{$basis} - #{$gap});
			flex-basis: $basis;
			display: flex;
			flex-grow: 1;
			min-width: $item-min-width;
			margin-bottom: $gap;
			padding-left: $gap;

			@include msie-only() {
				flex-basis: calc(#{$basis} - #{$gap});
			}
		}
	} @else if $gap-type == list {
		> * {
			display: flex;
			flex-grow: 1;
			min-width: $item-min-width;
		}

		$next-index: 1;

		@each $gap-value in $gap {
			$next-index: $next-index + 1;
			$length: length($gap-value);

			@if $length == 1 {
				margin-left: -$gap-value;

				> * {
					//flex-basis: calc(#{$basis} - #{$gap-value});
					flex-basis: $basis;
					margin-bottom: $gap-value;
					padding-left: $gap-value;

					@include msie-only() {
						flex-basis: calc(#{$basis} - #{$gap-value});
					}
				}
			} @else {
				$next-value: if( length($gap) >= $next-index, nth(nth($gap, $next-index), 1), false );
				$current-breakpoint: nth($gap-value, 1);
				$margin: nth($gap-value, 2);

				@if $next-value != false {
					@include bp(">= #{$current-breakpoint}", "< #{$next-value}") {
						margin-left: -$margin;

						> * {
							//flex-basis: calc(#{$basis} - #{$margin});
							flex-basis: $basis;
							margin-bottom: $margin;
							padding-left: $margin;

							@include msie-only() {
								flex-basis: calc(#{$basis} - #{$margin});
							}
						}
					}
				} @else {
					@include bp(">= #{$current-breakpoint}") {
						margin-left: -$margin;

						> * {
							//flex-basis: calc(#{$basis} - #{$margin});
							flex-basis: $basis;
							margin-bottom: $margin;
							padding-left: $margin;
							
							@include msie-only() {
								flex-basis: calc(#{$basis} - #{$margin});
							}
						}
					}
				}
			}
		}
	}
}

@mixin scrollable-items( $amount, $margin ) {
	$total: length(&);
	$selector-map: ();
	
	@each $s in & {
		$selector: list-pop($s);
		$selector-map: append($selector-map, $selector, comma);
	}

	$compound: implode($selector-map, ", ");

	@at-root {
		#{$compound} {
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;
			white-space: nowrap;
			font-size: 0;
		}
	}

	display: inline-block;
	vertical-align: top;
	white-space: normal;
	font-size: 1rem;
	
	$width: null;

	@if unitless($margin) {
		$margin: percentage(strip-unit($margin) / 100);
	}

	@if unit($margin) == unquote("%") {
		$marginTotal: percentage(strip-unit($margin) / 100);
		$width: (100% - ($marginTotal * ($amount - 1))) / $amount;
	} @else {
		$widthItem: 100% / $amount;
		$marginTotal: ( $margin * ( $amount - 1 ) ) / $amount;
		$width: if( $amount > 1, calc( #{$widthItem} - #{$marginTotal} ), 100%);
	}

	width: $width;
	margin-left: $margin;
	margin-top: 0;

	&:first-child {
		margin-left: 0;
	}

	@content;
}


@mixin link( $selector: a, $visited: true ) {
	@if $selector != & {
		$selector: unquote($selector);

		@if $visited == true {
			#{$selector},
			#{$selector}:link,
			#{$selector}:visited {
				@content;
			}
		} @else {
			#{$selector},
			#{$selector}:link {
				@content;
			}
		}
	} @else {
		&,
		&:link,
		&:visited {
			@content;
		}
	}
}

@mixin link-visited( $selector: a ) {
	@if $selector != & {
		$selector: unquote($selector);

		#{$selector}:visited {
			@content;
		}
	} @else {
		&:visited {
			@content;
		}
	}
}

@mixin link-over( $selector: a ) {
	@if $selector != & {
		$selector: unquote($selector);

		#{$selector}:hover,
		#{$selector}:focus,
		#{$selector}:active {
			@content;
		}
	} @else {
		&:hover,
		&:focus,
		&:active {
			@content;
		}
	}
}

@mixin link-all( $selector: a ) {
	@if $selector != & {
		$selector: unquote($selector);

		#{$selector},
		#{$selector}:link,
		#{$selector}:visited,
		#{$selector}:hover,
		#{$selector}:focus,
		#{$selector}:active {
			@content;
		}
	} @else {
		&,
		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			@content;
		}
	}
}


@mixin landscape {
	@media only screen and (orientation: landscape) {
		@content;
	}
}


@mixin portrait {
	@media only screen and (orientation: portrait) {
		@content;
	}
}


@mixin font-face( $fontFaceMap, $filePath) {
	$family: map-get($fontFaceMap, family);
	$weight: if( map-has-key($fontFaceMap, weight), map-get($fontFaceMap, weight), normal );
	$style: if( map-has-key($fontFaceMap, style), map-get($fontFaceMap, style), normal );

	@font-face {
		font: {
			family: "#{$family}";
			weight: $weight;
			style: $style;
		}

		src: url("#{$fileName}.woff2") format("woff2"),
			  url("#{$fileName}.woff") format("woff"),
			  url("#{$fileName}.ttf") format("truetype");
		font-display: swap;
	}
}


@mixin msie-only {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}


@mixin edge-only {
	@supports (-ms-ime-align: auto) {
		@content;
	}
}


@mixin iphonex-only {
	@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
		@content;
	}
}


@mixin bp( $expression, $expression2 : null ) {
	$operators: (
		">" : "min-width",
		">=" : "min-width",
		"<" : "max-width",
		"<=" : "max-width"
	);

	$space-index: str-index($expression, " ");
	$value: null;
	$operator: null;

	@if $space-index == null {
		$operator: ">";
		$value: to-number(unquote($expression));
	} @else {
		$operator: unquote(str-slice($expression, 1, $space-index - 1));
		$value: to-number(unquote(str-slice($expression, $space-index + 1)));
	}

	$isEm: unit($value) == em;

	@if $operator == ">" {
		$value: if($isEm, $value + .01em, $value + 1px);
	} @else if $operator == "<" {
		$value: if($isEm, $value - .01em, $value - 1px);
	}

	@if map-has-key($operators, $operator) {
		@if $expression2 == null {
			$query: map-get($operators, $operator);
			
			@media screen and ($query: $value) {
				@content;
			}
		} @else {
			$space-index-2: str-index($expression2, " ");
			$operator2: unquote(str-slice($expression2, 1, $space-index-2 - 1));
			$value2: to-number(unquote(str-slice($expression2, $space-index-2 + 1)));
			$isEm: unit($value2) == em;

			@if $operator2 == ">" {
				$value2: if($isEm, $value2 + .01em, $value2 + 1px);
			} @else if $operator2 == "<" {
				$value2: if($isEm, $value2 - .01em, $value2 - 1px);
			}

			@if map-has-key($operators, $operator2) {
				$query: map-get($operators, $operator);
				$query2: map-get($operators, $operator2);
			
				@media screen and ($query: $value) and ($query2: $value2) {
					@content;
				}
			} @else {
				@error "Neznamy operator #{$operator2}";
			}
		}
	} @else {
		@error "Neznamy operator #{$operator}";
	}
}

@mixin min( $value ) {
	@media screen and ( min-width: $value ) {
		@content;
	}
}

@mixin max( $value ) {
	@media screen and ( max-width: $value ) {
		@content;
	}
}

@mixin minmax( $value-min, $value-max ) {
	@media screen and ( max-width: $value-min ) and ( max-width: $value-max ) {
		@content;
	}
}


@mixin limit-items( $limit, $selector: null ) {
	@if $selector == null {
		$last: null;

		@each $s in & {
			$l: length($s);
			$last: nth($s, $l);
		}

		&:nth-of-type(#{$limit}) ~ #{$last} {
			display: none;
		}
	} @else {
		#{$selector}:nth-of-type(#{$limit}) ~ li {
			display: none;
		}
	}
}

@mixin quantity-query( $condition, $selector: & ) {
	$conditionComplexity: length($condition);

	$least: nth( $condition, 2 );
	$selectorMap: ();

	@each $s in & {
		$l: length($s);
		$last: nth($s, $l);

		$s: implode($s, " ");

		@if $conditionComplexity == 2 {
			$cond: nth( $condition, 1 );

			@if $cond == min {
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least})", space);
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}) ~ #{$last}", space);
			} @else if $cond == max {
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(-n+#{$least})", space);
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(-n+#{$least}) ~ #{$last}", space);
			} @else if $cond == exactly {
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child", space);
				$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child ~ #{$last}", space);	
			}
		} @else {
			$most: nth( $condition, 4 );
			$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child", space);
			$selectorMap: append($selectorMap, "#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child ~ #{$last}", space);
		}
	}

	$compound: implode($selectorMap, ", ");

	@at-root {
		#{$compound} {
			@content;
		}
	}
}


@mixin break-inside( $value ) {
	-webkit-column-break-inside: $value;
	page-break-inside: $value;
	break-inside: $value;
}


@mixin radius( $position, $value ) {
	$position: unquote($position);

	@if $position == top {
		border-radius: $value $value 0 0;
	} @else if $position == right {
		border-radius: 0 $value $value 0;
	} @else if $position == bottom {
		border-radius: 0 0 $value $value;
	} @else if $position == left {
		border-radius: $value 0 0 $value;
	} @else {
		@debug "Mixin radius - parametr $position musi byt top/right/bottom/left";
	}
}

@mixin fill( $offsets : 0 0 0 0, $position: absolute ) {
	$top: nth($offsets, 1);
	$right: nth($offsets, 2);
	$bottom: nth($offsets, 3);
	$left: nth($offsets, 4);

	$top: if($top, $top, 0);
	$right: if($right, $right, 0);
	$bottom: if($bottom, $bottom, 0);
	$left: if($left, $left, 0);

	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

@mixin pad( $color, $side : left, $element : before, $width : 999em ) {
	$from : if( $side == right, left, right );

	&::#{$element} {
		content: "";
		position: absolute;
		width: $width;
		#{$from}: 100%;
		top: 0;
		bottom: 0;
		background-color: $color;
	}
}

@mixin headings( $from, $through ) {
	$start: clamp($from, 1, 6);
	$end: clamp($through, 1, 6);
	$selectors: ();

	@for $level from $start through $end { 
		$selectors: append($selectors, "h#{$level}", comma);
	}

	$compound: implode($selectors, ", ");

	#{$compound} {
		@content;
	}
}

@mixin clear() {
	&::after {
		content: "";
		height: 0;
		display: table;
		clear: both;
		width: 100%;
	}
}

@mixin unset-styles( $selector: input ) {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent none;
	-webkit-box-shadow: none;
	box-shadow: none;

	&:focus {
		box-shadow: none;
		outline: 0;
	}

	@if $selector == select {
		&::-ms-expand {
			border: 0;
			background: transparent none;
		}
	}
}