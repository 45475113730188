
.attachment {
	text-align: left;

	&__link {
		display: flex;
		position: relative;
		flex-direction: column-reverse
	}

	&__title {
		display: block;
		word-wrap: break-word;
	}

	&__type {
		font-size: rem(18px);
		text-transform: uppercase;
		font-weight: bold;
		text-align: center;
	}

	@include link(".attachment__link") {
		text-decoration: none;
	}

	&__details:not(:first-child) {
		$icon-width: 6rem;
		$icon-height: 7rem;
		$icon-clip: 1.5rem;
		$icon-radius: 6px;

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		margin: 0 0 1em;
		width: $icon-width;
		height: $icon-height;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			width: $icon-width;
			height: $icon-height;
			background-color: #EEEEEE;
			border-radius: $icon-radius;
			margin-bottom: .75em;
			clip-path: polygon(0 0, #{($icon-width)-($icon-clip)} 0, #{$icon-width} #{$icon-clip}, 100% 100%, 0 100%, 0 0);
		}

		&::after {
			content: "";
			border-radius: 0 0 0 $icon-radius;
			width: $icon-clip;
			height: $icon-clip;
			background-color: #C1C1C1;
			position: absolute;
			left: #{($icon-width)-($icon-clip)};
			top: 0;
			z-index: 2;
			clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
		}
	}

	&__link[data-attachment-type=""] {
		.attachment__details:not(:first-child) {
			&::before {
				background: {
					image: encode-svg('<svg width="47" height="47" viewBox="0 0 47 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;"><circle cx="23.475" cy="23.475" r="21.475" style="fill:none;stroke:rgb(193,193,193);stroke-width:4px;"/><path d="M22,21.127L22,13.563L26.695,13.563L26.695,21.127L33.822,21.127L33.822,25.822L26.695,25.822L26.695,33.385L22,33.385L22,25.822L14,25.822L14,21.127L22,21.127Z" style="fill:rgb(193,193,193);"/></svg>');
					repeat: no-repeat;
					position: 50% 50%;
				}
			}
		}

		&:hover {
			.attachment__details:not(:first-child) {
				&::before {
					background-image: encode-svg('<svg width="47" height="47" viewBox="0 0 47 47" version="1.1" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;"><circle cx="23.475" cy="23.475" r="21.475" style="fill:none;stroke:rgb(220,24,52);stroke-width:4px;"/><path d="M22,21.127L22,13.563L26.695,13.563L26.695,21.127L33.822,21.127L33.822,25.822L26.695,25.822L26.695,33.385L22,33.385L22,25.822L14,25.822L14,21.127L22,21.127Z" style="fill:rgb(220,24,52);"/></svg>');
				}
			}
		}
	}
}
