
.document {
	margin: 0 0 1.5em;
	@include flex(df, aifs);

    &__image {
        display: none;
        max-width: 240px;
        margin:0 2em 0 0;
        flex-shrink: 0;
  
        @include bp("> 600px") {
           display: inline-block;
           float: left;
        } 
    }
    &__body{
        width: 100%;
        .-board &{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .to_board__link{
                margin-left: auto;
                align-self: flex-end;
            }
        }
    }

    &__title {
        margin: 0 0 .15em;
        font-size: rem(24px);
        font-weight: normal;
        padding: 0;
    }

	/*&__date {
      display: block;
	}*/

}

.board_document {
	margin: 0 0 1.5em;
	@include flex(df, aifs, fdc);

    &__image {
        display: none;
        max-width: 240px;
        margin-right: 2em;
        flex-shrink: 0;

        @include bp("> 600px") {
            display: inline-block;
            float: left;
        }
    }
    &__body{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 1em;
        .to_board__link{
            margin-left: auto;
            align-self: flex-end;
            line-height: 2;
            padding-left: 1em;
        }
    }

    &__title {
        margin: 0 0 .15em;
        font-size: rem(24px);
        font-weight: normal;
        padding: 0;
    }
}

.site_board{
    font-size: rem(14px);
    width: 100%;
    &__image {
        display: none;
        max-width: 240px;
        margin-right: 2em;
        flex-shrink: 0;

        @include min(600px){
            display: inline-block;
            float: left;
        }
    }
    &__title {
        margin: 0 0 .15em;
        font-size: rem(20px);
        font-weight: 500;
        padding: 0 0 .5em;
        .board_document &{
            font-size: rem(17px);
        }
    }
    &__description{
        margin: 0 0 1em;
        font-size: rem(15px);
        .board_document &{
            font-size: rem(14px);
        }
    }
    &__list{
        padding: 0;
        margin: 0 0 1.5em;
    }
    &__item{
        display: flex;
        padding: 1.5em 1.5em;
        flex-direction: column;
        &:nth-child(odd){
            background-color: #ececec;
        }
        //skryti slozky dokumentu ve vypisu podle slozek dokumentu
        .list__item & .site_board__folder{
            display: none;
        }
        @include min(600px){
            flex-direction: row;
        }
        @include min(800px){
            flex-direction: column;
        }
        @include min(1024px){
            flex-direction: row;
        }
    }
    &__container{
        &.-dates{
            margin-left: auto;
            padding: 0.75em 0 0 2em;
            @include flex(df, aifs, fdc);
            @include min(600px){
                padding: 0 0 0 2em;
            }
        }
    }
    &__date{
        white-space: nowrap;
    }
}

//souvisecijici odkazy
.article__body .list.documents{
    overflow: hidden;
}
.article-link{
    &__image {
        display: none;
        max-width: 240px;
        margin:0 2em 0 0;
        flex-shrink: 0;
    
        @include bp("> 600px") {
            display: inline-block;
            float: left;
        }
    }
    &__title{
        font-size: rem(18px);
    }
    &__description{
        margin-top: 0;
    }
}

/*@include link(".document__link", false) {
	color: $default-document-link-color;
    text-decoration: underline;
    .document__title{
        color: $default-document-link-color;
    }
}

@include link-visited(".document__link") {
    color: darken($default-document-link-color, 10%);
    .document__title{
        color: darken($default-document-link-color, 10%);
    }
}

@include link-over(".document__link") {
	text-decoration: none;
}*/