/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/
/*
* BASE DEPENDENCIES
*/
/*! 
*   ECCO SASS knihovna
*   @version: 3.0.0
*   @author: JakubKo
*   @lastchange: 7. 5. 2018 
*/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/*==============================
	DEFAULTS
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
@keyframes fade-into-foreground {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.2);
    opacity: 0;
  }
}

@keyframes fade-into-background {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.75);
    opacity: 0;
  }
}

@keyframes pop-into-foreground {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pop-into-foreground-with-bounce {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  72% {
    transform: scale(1.07);
    opacity: .72;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0);
  }
}

/*
* COMMON BLOCKS
*/
.document {
  margin: 0 0 1.5em;
  display: flex;
  align-items: flex-start;
  /*&__date {
      display: block;
	}*/
}

.document__image {
  display: none;
  max-width: 240px;
  margin: 0 2em 0 0;
  flex-shrink: 0;
}

@media screen and (min-width: 601px) {
  .document__image {
    display: inline-block;
    float: left;
  }
}

.document__body {
  width: 100%;
}

.-board .document__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.-board .document__body .to_board__link {
  margin-left: auto;
  align-self: flex-end;
}

.document__title {
  margin: 0 0 .15em;
  font-size: 1.5rem;
  font-weight: normal;
  padding: 0;
}

.board_document {
  margin: 0 0 1.5em;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.board_document__image {
  display: none;
  max-width: 240px;
  margin-right: 2em;
  flex-shrink: 0;
}

@media screen and (min-width: 601px) {
  .board_document__image {
    display: inline-block;
    float: left;
  }
}

.board_document__body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 1em;
}

.board_document__body .to_board__link {
  margin-left: auto;
  align-self: flex-end;
  line-height: 2;
  padding-left: 1em;
}

.board_document__title {
  margin: 0 0 .15em;
  font-size: 1.5rem;
  font-weight: normal;
  padding: 0;
}

.site_board {
  font-size: 0.875rem;
  width: 100%;
}

.site_board__image {
  display: none;
  max-width: 240px;
  margin-right: 2em;
  flex-shrink: 0;
}

@media screen and (min-width: 600px) {
  .site_board__image {
    display: inline-block;
    float: left;
  }
}

.site_board__title {
  margin: 0 0 .15em;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0 0 .5em;
}

.board_document .site_board__title {
  font-size: 1.0625rem;
}

.site_board__description {
  margin: 0 0 1em;
  font-size: 0.9375rem;
}

.board_document .site_board__description {
  font-size: 0.875rem;
}

.site_board__list {
  padding: 0;
  margin: 0 0 1.5em;
}

.site_board__item {
  display: flex;
  padding: 1.5em 1.5em;
  flex-direction: column;
}

.site_board__item:nth-child(odd) {
  background-color: #ececec;
}

.list__item .site_board__item .site_board__folder {
  display: none;
}

@media screen and (min-width: 600px) {
  .site_board__item {
    flex-direction: row;
  }
}

@media screen and (min-width: 800px) {
  .site_board__item {
    flex-direction: column;
  }
}

@media screen and (min-width: 1024px) {
  .site_board__item {
    flex-direction: row;
  }
}

.site_board__container.-dates {
  margin-left: auto;
  padding: 0.75em 0 0 2em;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .site_board__container.-dates {
    padding: 0 0 0 2em;
  }
}

.site_board__date {
  white-space: nowrap;
}

.article__body .list.documents {
  overflow: hidden;
}

.article-link__image {
  display: none;
  max-width: 240px;
  margin: 0 2em 0 0;
  flex-shrink: 0;
}

@media screen and (min-width: 601px) {
  .article-link__image {
    display: inline-block;
    float: left;
  }
}

.article-link__title {
  font-size: 1.125rem;
}

.article-link__description {
  margin-top: 0;
}

/*@include link(".document__link", false) {
	color: $default-document-link-color;
    text-decoration: underline;
    .document__title{
        color: $default-document-link-color;
    }
}

@include link-visited(".document__link") {
    color: darken($default-document-link-color, 10%);
    .document__title{
        color: darken($default-document-link-color, 10%);
    }
}

@include link-over(".document__link") {
	text-decoration: none;
}*/
/*
* DESIGN COMPONENTS
*/
.gallery {
  list-style-type: none;
  margin: 0 0 2em;
  padding: 0;
  grid-gap: 25px;
}

.gallery__item {
  position: relative;
}

.gallery__link {
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  text-decoration: none;
}

.gallery__link[data-image-desc]::after {
  content: attr(data-image-desc);
  line-height: 1.3;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(0);
  opacity: 0;
  padding: .7em 1em;
  color: #ffffff;
  transition: backdrop-filter .2s ease-out, opacity .25s ease-out;
  overflow: hidden;
  text-overflow: ellipsis;
}

.gallery__link[data-image-desc]:hover::after {
  opacity: 1;
  backdrop-filter: blur(12px);
}

.gallery__link[data-image-desc]:focus-within::after {
  opacity: 1;
  backdrop-filter: blur(12px);
}

.gallery__image {
  object-fit: cover;
  flex-grow: 1;
  transition: transform .3s ease-out;
  transform: translate3d(0, 0, 1px) scale(1);
}

.gallery__link:not(.-placeholder):hover, .gallery__link:not(.-placeholder):focus, .gallery__link:not(.-placeholder):active {
  z-index: 2;
}

.gallery__link:not(.-placeholder):hover .gallery__image, .gallery__link:not(.-placeholder):focus .gallery__image, .gallery__link:not(.-placeholder):active .gallery__image {
  transform: translate3d(0, 0, 1px) scale(1.15);
}

.gallery__link.-placeholder {
  background-color: #eee;
}

.pagination {
  margin: 0 0 3em 0;
}

.pagination__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.pagination__item {
  display: inline;
}

.pagination__item:not(:first-child) {
  margin-left: .5em;
}

.pagination__item.-first::before {
  content: "\007c\003c ";
}

.pagination__item.-previous::before {
  content: "\003c\003c ";
}

.pagination__item.-next::after {
  content: " \003e\003e";
}

.pagination__item.-last::after {
  content: " \003e\007c";
}

.pagination__link[aria-disabled='true'],
.pagination__link[aria-disabled='true']:link,
.pagination__link[aria-disabled='true']:visited,
.pagination__link[aria-disabled='true']:hover,
.pagination__link[aria-disabled='true']:focus,
.pagination__link[aria-disabled='true']:active {
  pointer-events: none;
  text-decoration: none;
  opacity: .65;
}

.attachment {
  text-align: left;
}

.attachment__link {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
}

.attachment__title {
  display: block;
  word-wrap: break-word;
}

.attachment__type {
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.attachment .attachment__link,
.attachment .attachment__link:link,
.attachment .attachment__link:visited {
  text-decoration: none;
}

.attachment__details:not(:first-child) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 0 1em;
  width: 6rem;
  height: 7rem;
}

.attachment__details:not(:first-child)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 6rem;
  height: 7rem;
  background-color: #EEEEEE;
  border-radius: 6px;
  margin-bottom: .75em;
  clip-path: polygon(0 0, 4.5rem 0, 6rem 1.5rem, 100% 100%, 0 100%, 0 0);
}

.attachment__details:not(:first-child)::after {
  content: "";
  border-radius: 0 0 0 6px;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #C1C1C1;
  position: absolute;
  left: 4.5rem;
  top: 0;
  z-index: 2;
  clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
}

.attachment__link[data-attachment-type=""] .attachment__details:not(:first-child)::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='47' height='47' viewBox='0 0 47 47' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Ccircle cx='23.475' cy='23.475' r='21.475' style='fill:none;stroke:rgb(193,193,193);stroke-width:4px;'/%3E%3Cpath d='M22,21.127L22,13.563L26.695,13.563L26.695,21.127L33.822,21.127L33.822,25.822L26.695,25.822L26.695,33.385L22,33.385L22,25.822L14,25.822L14,21.127L22,21.127Z' style='fill:rgb(193,193,193);'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.attachment__link[data-attachment-type=""]:hover .attachment__details:not(:first-child)::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='47' height='47' viewBox='0 0 47 47' version='1.1' xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='fill-rule:evenodd;clip-rule:evenodd;'%3E%3Ccircle cx='23.475' cy='23.475' r='21.475' style='fill:none;stroke:rgb(220,24,52);stroke-width:4px;'/%3E%3Cpath d='M22,21.127L22,13.563L26.695,13.563L26.695,21.127L33.822,21.127L33.822,25.822L26.695,25.822L26.695,33.385L22,33.385L22,25.822L14,25.822L14,21.127L22,21.127Z' style='fill:rgb(220,24,52);'/%3E%3C/svg%3E");
}
