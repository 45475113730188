
/*!
*  Author: WEBHOUSE, s. r. o.
*  Version: 1.0
*/


/*
* BASE DEPENDENCIES
*/

@import
	"ecco";


@import
	"shared/vars",
	"shared/animations";

/*
* COMMON BLOCKS
*/

@import 
    "common/_page",
    "common/_document";

/*
* DESIGN COMPONENTS
*/

@import
	"blocks/gallery",
	"blocks/pagination",
    "blocks/attachment";
